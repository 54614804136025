import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import { usePrefs, useRequestData, useLabels } from 'wsm-common-data';

import TabsControls from './TabsControls';

const Tabs = React.forwardRef(({ children, id, onSelect, activeKey }, ref) => {
	const { displayStyle } = usePrefs();
	const { deviceType } = useRequestData();
	const isCarousel = displayStyle === 'carousel' && deviceType !== 'MOBILE';

	const tabsClassNames = setClassNames([
		'nav',
		'nav-pills',
		'tabs-container',
		isCarousel ? 'carousel' : null
	]);

	const activeChildren = children.find(
		(_child) => _child.props.eventKey === activeKey
	);

	return (
		<div id={id} className="tabs">
			<TabsControls tabSliderRef={ref} />

			<div ref={ref} role="tablist" className={tabsClassNames}>
				{React.Children.toArray(
					children.map((_tab, _index) => {
						const { title, eventKey } = _tab.props;
						const isActiveTab = activeKey === eventKey;
						return (
							<TabTitle
								id={id}
								tabIndex={0}
								title={title}
								onSelect={onSelect}
								eventKey={eventKey}
								isActive={isActiveTab}
							/>
						);
					})
				)}
			</div>

			<div className="tab-content">
				{React.cloneElement(activeChildren, {
					parentId: id
				})}
			</div>
		</div>
	);
});

const TabTitle = ({ title, id, isActive, onSelect, eventKey, tabIndex }) => {
	const labels = useLabels();
	const { widgetName, windowId } = useRequestData();

	const tabId = `${id}-tab-${eventKey}`;
	const tabTitleClasses = setClassNames([
		'mx-2',
		'tab-item',
		'text-nowrap',
		'ddc-font-size-xsmall',
		isActive && 'active'
	]);

	const onSelectTab = React.useCallback(() => {
		onSelect(eventKey);
		trackEvent(widgetName, windowId, {
			action: 'clicked',
			element: tabId,
			elementCTA: labels.get(title),
			result: 'Model selector filtered'
		});
	}, [onSelect, eventKey, labels, tabId, title, widgetName, windowId]);

	return (
		<button
			href="#"
			role="tab"
			id={tabId}
			key={tabId}
			type="button"
			tabIndex={tabIndex}
			className={tabTitleClasses}
			aria-selected={isActive}
			onClick={onSelectTab}
			ui-tabs-initial-load="false"
			aria-controls={`${id}-pane-${eventKey}`}
		>
			{title}
		</button>
	);
};

TabTitle.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	isActive: PropTypes.bool,
	onSelect: PropTypes.func,
	tabIndex: PropTypes.number,
	eventKey: PropTypes.string
};

Tabs.propTypes = {
	id: PropTypes.string,
	onSelect: PropTypes.func,
	activeKey: PropTypes.string,
	children: PropTypes.arrayOf(PropTypes.element)
};

export default Tabs;
