export const getFormattedPrice = (locale = '', vehicle) => {
	let currency = 'USD';
	if (locale.endsWith('CA')) {
		currency = 'CAD';
	} else if (locale.endsWith('MX')) {
		currency = 'MXN';
	}

	const price = vehicle.minStartingAtPrice
		? new Intl.NumberFormat(locale.replace('_', '-'), {
				style: 'currency',
				currency,
				maximumFractionDigits: 0
		  }).format(vehicle.minStartingAtPrice)
		: '';
	return price;
};
