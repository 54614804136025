import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, useRequestData, usePrefs } from 'wsm-common-data';

import debounce from '../utils/debounce';
import SecondaryNav from './SecondaryNav';

const Slider = ({
	children,
	secondaryNavLabels = [],
	sliderContentClassnames,
	controlsContainerClassnames
}) => {
	const labels = useLabels();
	const sliderRef = React.useRef();
	const { displayStyle } = usePrefs();
	const { widgetName, windowId, deviceType } = useRequestData();
	const [scrollStatus, setScrollStatus] = React.useState('start');

	const [activeVehicle, setActiveVehicle] = React.useState(0);

	const shouldRenderSecondaryNav =
		secondaryNavLabels.length > 0 &&
		deviceType === 'DESKTOP' &&
		displayStyle === 'largeVehicle';

	const isMobile = deviceType === 'MOBILE';

	const isDisabledLeft =
		scrollStatus === 'start' || scrollStatus === 'non-scrollable';
	const isDisabledRight =
		scrollStatus === 'end' || scrollStatus === 'non-scrollable';

	const handleNext = React.useCallback(
		debounce(() => {
			sliderRef.current.scrollTo({
				behavior: 'smooth',
				left:
					sliderRef.current?.scrollLeft +
					sliderRef.current?.offsetWidth
			});
			trackEvent(widgetName, windowId, {
				action: 'clicked',
				element: 'Next vehicle arrow',
				elementCTA: labels.get('NEXT_VEHICLE'),
				result: 'Scrolled to next model'
			});
		}, 200),
		[sliderRef, labels, widgetName, windowId]
	);

	const handlePrev = React.useCallback(
		debounce(() => {
			sliderRef.current.scrollTo({
				behavior: 'smooth',
				left:
					sliderRef.current?.scrollLeft -
					sliderRef.current?.offsetWidth
			});
			trackEvent(widgetName, windowId, {
				action: 'clicked',
				element: 'Prev vehicle arrow',
				elementCTA: labels.get('PREVIOUS_VEHICLE'),
				result: 'Scrolled to previous model'
			});
		}, 200),
		[sliderRef, labels, widgetName, windowId]
	);

	const sliderClassnames = setClassNames([
		'slider-content',
		'overflow-auto',
		sliderContentClassnames,
		scrollStatus === 'non-scrollable' ? 'justify-content-center' : '',
		isMobile ? 'mb-8' : ''
	]);

	const sliderControlsContainerClassnames = setClassNames([
		'slider-controls',
		'justify-content-between',
		'position-absolute',
		'd-flex',
		'px-2',
		controlsContainerClassnames
	]);

	const sliderControlsContainerClassnamesMobile = setClassNames([
		'slider-controls-mobile',
		'justify-content-end',
		'position-absolute',
		'd-flex',
		'px-4',
		controlsContainerClassnames
	]);

	const controlClassnames = setClassNames([
		'slider-control',
		'ddc-icon',
		'ddc-icon-size-xlarge',
		'text-white'
	]);

	const leftButtonClassnames = setClassNames([
		'btn',
		'previous',
		'btn-carousel',
		'position-relative',
		'slider-control-container',
		'slider-control-container-left',
		'p-3'
	]);

	const rightButtonClassnames = setClassNames([
		'btn',
		'next',
		'btn-carousel',
		'position-relative',
		'slider-control-container',
		'slider-control-container-right',
		'p-3',
		isMobile ? 'mobile-button' : ''
	]);

	const scrollToItem = (index) => {
		const itemWidth =
			sliderRef.current?.scrollWidth / secondaryNavLabels.length;
		sliderRef.current.scrollTo({
			behavior: 'smooth',
			left: itemWidth * index
		});
	};

	const onScroll = debounce((event) => {
		let auxStatus = 'navigating';

		if (event.target?.scrollLeft === 0) {
			auxStatus = 'start';
		} else if (
			event.target?.scrollLeft >
			sliderRef.current?.scrollWidth - sliderRef.current?.offsetWidth - 5
		) {
			auxStatus = 'end';
		}

		const itemWidth =
			sliderRef.current?.scrollWidth / secondaryNavLabels.length;

		const currentVehicle = event.target?.scrollLeft / itemWidth;

		setScrollStatus(auxStatus);
		setActiveVehicle(Math.round(currentVehicle));
	}, 100);

	React.useEffect(() => {
		const auxRef = sliderRef.current;
		if (
			auxRef.scrollWidth !== 0 &&
			auxRef.scrollWidth <= auxRef.offsetWidth
		) {
			setScrollStatus('non-scrollable');
		}

		auxRef.addEventListener('scroll', onScroll);
		return () => auxRef.removeEventListener('scroll', onScroll);
	}, [sliderRef, onScroll]);

	return (
		<div
			data-testid="slider-component-test"
			className="slider position-relative"
		>
			{!shouldRenderSecondaryNav && (
				<div
					className={
						isMobile
							? sliderControlsContainerClassnamesMobile
							: sliderControlsContainerClassnames
					}
				>
					<button
						tabIndex={0}
						type="button"
						onClick={handlePrev}
						aria-label={labels.get('PREVIOUS_VEHICLE')}
						onKeyDown={(event) => {
							if (event.key === 'Enter') {
								handlePrev();
							}
						}}
						disabled={isDisabledLeft}
						className={leftButtonClassnames}
					>
						<i
							className={`${controlClassnames} ddc-icon-carousel-arrow ddc-icon-carousel-arrow-left d-block`}
							aria-hidden="true"
						/>
					</button>

					<button
						tabIndex={0}
						type="button"
						onClick={handleNext}
						aria-label={labels.get('NEXT_VEHICLE')}
						onKeyDown={(event) => {
							if (event.key === 'Enter') {
								handleNext();
							}
						}}
						disabled={isDisabledRight}
						className={rightButtonClassnames}
					>
						<i
							className={`${controlClassnames} ddc-icon-carousel-arrow ddc-icon-carousel-arrow-right d-block`}
							aria-hidden="true"
						/>
					</button>
				</div>
			)}

			{shouldRenderSecondaryNav && (
				<SecondaryNav
					leftFunction={handlePrev}
					rightFunction={handleNext}
					activeItem={activeVehicle}
					items={secondaryNavLabels}
					itemFunction={scrollToItem}
					isDisabledLeft={isDisabledLeft}
					isDisabledRight={isDisabledRight}
				/>
			)}

			<div ref={sliderRef} className={sliderClassnames}>
				{children}
			</div>
		</div>
	);
};

export default Slider;

Slider.propTypes = {
	secondaryNavLabels: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.arrayOf(PropTypes.element).isRequired,
	sliderContentClassnames: PropTypes.string,
	controlsContainerClassnames: PropTypes.string
};
