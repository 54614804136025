import { SET_WIDGET_DATA } from '../actions/types';

const initialState = {
	make: '',
	siteId: '',
	pageAlias: ''
};

export default function widgetData(
	state = initialState,
	{ type, payload, error }
) {
	if (!error && payload !== undefined) {
		switch (type) {
			case SET_WIDGET_DATA:
				return { ...state, ...payload };

			default:
				return state;
		}
	}

	return state;
}
