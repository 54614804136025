import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';

const SecondaryNav = ({
	items,
	activeItem,
	leftFunction,
	itemFunction,
	rightFunction,
	isDisabledLeft,
	isDisabledRight
}) => {
	const labels = useLabels();

	const btnClassnames = setClassNames([
		'btn',
		'p-3',
		'previous',
		'bg-transparent',
		'position-relative',
		'slider-control-container',
		'slider-control-container-left'
	]);

	const controlClassnames = setClassNames([
		'slider-control',
		'ddc-icon',
		'ddc-icon-size-xlarge',
		'text-white'
	]);

	const tabTitleClasses = setClassNames([
		'mx-2',
		'tab-item',
		'text-nowrap',
		'ddc-font-size-xsmall'
	]);

	return (
		<div className="secondary-nav" data-testid="secondary-nav">
			<div role="tablist" className="nav nav-pills items-container">
				{React.Children.toArray(
					items.map((_item, _index) => (
						<button
							role="tab"
							type="button"
							data-testid="button-in-pills"
							onClick={() => {
								itemFunction(_index);
							}}
							className={setClassNames([
								tabTitleClasses,
								activeItem === _index ? 'active' : ''
							])}
						>
							{_item}
						</button>
					))
				)}
			</div>

			<div className="line" />

			<button
				tabIndex={0}
				type="button"
				onClick={leftFunction}
				className={btnClassnames}
				disabled={isDisabledLeft}
				aria-label={labels.get('PREVIOUS_VEHICLE')}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						leftFunction();
					}
				}}
			>
				<i
					data-testid="button-arrow-left"
					aria-hidden="true"
					className={`${controlClassnames} ddc-icon-carousel-arrow ddc-icon-carousel-arrow-left d-block`}
				/>
			</button>

			<button
				tabIndex={0}
				type="button"
				onClick={rightFunction}
				className={btnClassnames}
				disabled={isDisabledRight}
				aria-label={labels.get('NEXT_VEHICLE')}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						rightFunction();
					}
				}}
			>
				<i
					data-testid="button-arrow-right"
					aria-hidden="true"
					className={`${controlClassnames} ddc-icon-carousel-arrow ddc-icon-carousel-arrow-right  d-block`}
				/>
			</button>
		</div>
	);
};

export default SecondaryNav;

SecondaryNav.propTypes = {
	leftFunction: PropTypes.func,
	itemFunction: PropTypes.func,
	activeItem: PropTypes.number,
	rightFunction: PropTypes.func,
	isDisabledLeft: PropTypes.bool,
	isDisabledRight: PropTypes.bool,
	items: PropTypes.arrayOf(PropTypes.string).isRequired
};
