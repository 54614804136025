import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { setClassNames } from 'ddc-classnames-js';
import { usePrefs, useRequestData } from 'wsm-common-data';

const imageResolutions = {
	mobile: 325,
	grid: 520,
	largeVehicle: 1400,
	carousel: 520
};

// eslint-disable-next-line react/prop-types
export const ModelImage = ({ url, altImage }) => {
	const imageRef = useRef();
	const { displayStyle } = usePrefs();
	const { deviceType } = useRequestData();
	const [isInView, setIsInView] = useState(false);

	let imageResolution;

	if (deviceType === 'MOBILE') {
		imageResolution = imageResolutions.mobile;
	} else {
		imageResolution = imageResolutions[displayStyle];
	}

	const imgClassNames = [
		'mx-auto',
		url ? 'progressive-image' : null,
		url && isInView ? 'loaded' : null
	];

	const imageSrc = url
		? `${url}?impolicy=downsize_bkpt&imdensity=1&w=${
				isInView ? imageResolution : '48'
		  }`
		: 'https://images.dealer.com/ddc/ws-model-selector/no-image.svg';

	useEffect(() => {
		const imageRefCurrent = imageRef.current;
		if (typeof IntersectionObserver !== 'undefined' && url) {
			const observer = new IntersectionObserver(
				async ([entry], _observer) => {
					if (entry.isIntersecting) {
						setTimeout(() => {
							setIsInView(true);
						}, 500);
						_observer.unobserve(imageRefCurrent);
					}
				},
				{
					threshold: 0.7
				}
			);
			observer.observe(imageRefCurrent);
			return () => observer.unobserve(imageRefCurrent);
		} else {
			return null;
		}
	}, [imageRef, url]);

	return (
		<img
			src={imageSrc}
			alt={altImage}
			ref={imageRef}
			data-testid="model-image"
			className={setClassNames(imgClassNames)}
		/>
	);
};

ModelImage.propTypes = {
	url: PropTypes.string,
	altImage: PropTypes.string
};

ModelImage.defaultProps = {
	url: ''
};
