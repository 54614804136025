import React, { useRef, useState, useEffect } from 'react';
import { usePrefs, useRequestData } from 'wsm-common-data';
import Skeleton from 'wsm-skeleton';
import { setClassNames } from 'ddc-classnames-js';
import { isBrowser } from 'ws-scripts/modules/environment';
import { getColumnClass } from '../utils/getColumnClass.js';

export const Loading = () => {
	const { deviceType } = useRequestData();
	const { displayStyle } = usePrefs();
	const [columnClass, setColumnClass] = useState('col-xs-12');
	const containerRef = useRef();
	const displayStyleSkeletons = {
		grid: {
			skeletonHeight: deviceType === 'MOBILE' ? '28rem' : '17rem',
			skeletonHeadingTitleSpacing:
				deviceType === 'MOBILE' ? 'mb-4' : 'mb-9'
		},
		carousel: {
			skeletonHeight: deviceType === 'MOBILE' ? '28rem' : '12rem',
			skeletonHeadingTitleSpacing:
				deviceType === 'MOBILE' ? 'mb-4' : 'mb-5'
		},
		largeVehicle: {
			skeletonHeight: deviceType === 'MOBILE' ? '28rem' : '30rem',
			skeletonHeadingTitleSpacing:
				deviceType === 'MOBILE' ? 'mb-4' : 'mb-5'
		}
	};

	useEffect(() => {
		if (isBrowser && containerRef.current) {
			setColumnClass(
				getColumnClass(
					displayStyle === 'largeVehicle' ? 'MOBILE' : deviceType
				)
			);
		}
	}, [containerRef, deviceType, displayStyle]);
	const colClassNames = [columnClass, 'model-skeleton'];
	const getModelMarkup = () => {
		const modelMarkup = [];
		const numberOfVehicles = 12 / columnClass.replace('col-xs-', '');
		for (let i = 0; i < numberOfVehicles; i++) {
			modelMarkup.push(
				<div
					className={setClassNames(colClassNames)}
					key={`model-${i}`}
				>
					<Skeleton
						height={
							displayStyleSkeletons[displayStyle].skeletonHeight
						}
						width="100%"
					/>
				</div>
			);
		}
		return modelMarkup;
	};

	return (
		<div
			className="loading-container py-3"
			ref={containerRef}
			data-testid="loading-test"
		>
			<div
				className={`tab-skeleton col-xs-12 ${displayStyleSkeletons[displayStyle].skeletonHeadingTitleSpacing}`}
			>
				<Skeleton height="3rem" width="100%" />
			</div>
			{getModelMarkup()}
		</div>
	);
};
