import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';
import { useDimensions } from '../utils/useDimensions';

const TabsControls = ({ tabSliderRef }) => {
	const labels = useLabels();
	const { width } = useDimensions();
	const [scrollStatus, setScrollStatus] = React.useState('start');
	const [desktopControls, setDesktopControls] = React.useState(false);

	const handleScrollToRight = React.useCallback(() => {
		tabSliderRef.current.scrollTo({
			behavior: 'smooth',
			left:
				tabSliderRef.current?.scrollLeft +
				tabSliderRef.current?.offsetWidth
		});
	}, [tabSliderRef]);

	const handleScrollToLeft = React.useCallback(() => {
		tabSliderRef.current.scrollTo({
			behavior: 'smooth',
			left:
				tabSliderRef.current?.scrollLeft -
				tabSliderRef.current?.offsetWidth
		});
	}, [tabSliderRef]);

	const onScroll = React.useCallback(
		(event) => {
			if (!event.target?.scrollLeft) {
				setScrollStatus('start');
			} else if (
				event.target?.scrollLeft >
				tabSliderRef.current?.scrollWidth -
					tabSliderRef.current?.offsetWidth -
					5
			) {
				setScrollStatus('end');
			} else {
				setScrollStatus('navigating');
			}
		},
		[tabSliderRef, setScrollStatus]
	);

	React.useEffect(() => {
		if (
			tabSliderRef.current?.scrollWidth >
			tabSliderRef.current?.clientWidth
		) {
			setDesktopControls(true);
			return;
		}

		if (width < tabSliderRef.current?.scrollWidth) {
			setDesktopControls(true);
		} else {
			setDesktopControls(false);
		}
	}, [tabSliderRef, width]);

	React.useEffect(() => {
		const auxRef = tabSliderRef.current;
		auxRef.addEventListener('scroll', onScroll);
		return () => auxRef.removeEventListener('scroll', onScroll);
	}, [tabSliderRef, onScroll]);

	return (
		desktopControls && (
			<div className="tab-controls-container position-relative w-100">
				{scrollStatus !== 'start' && (
					<div className="tab-control left-control position-absolute">
						<i
							tabIndex={0}
							role="button"
							onKeyDown={() => {}}
							onClick={handleScrollToLeft}
							aria-label={labels.get('PREVIOUS_BODYSTYLE')}
							className="ddc-icon ddc-icon-skinny-arrow-left ddc-icon-size-xlarge"
						/>
					</div>
				)}

				{scrollStatus !== 'end' && (
					<div className="tab-control right-control position-absolute">
						<i
							tabIndex={0}
							role="button"
							onKeyDown={() => {}}
							onClick={handleScrollToRight}
							aria-label={labels.get('NEXT_BODYSTYLE')}
							className="ddc-icon ddc-icon-skinny-arrow-right ddc-icon-size-xlarge"
						/>
					</div>
				)}
			</div>
		)
	);
};

export default TabsControls;

TabsControls.propTypes = {
	tabSliderRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	])
};

TabsControls.defaultProps = {};
