import React from 'react';
import PropTypes from 'prop-types';
import { useSitemap, usePrefs } from 'wsm-common-data';
import { getModelTitle } from '../../utils/getModelTitle';

import Slider from '../Slider';
import LargeVehicleCard from '../cards/Large';

import { getModelLink } from '../../utils/getModelLink';

const LargeVehicleContainer = ({ vehicles }) => {
	const prefs = usePrefs();
	const sitemap = useSitemap();
	const {
		modelLinkAlias,
		modelLinkParameters,
		largeVehicleModelNavigationLabel
	} = prefs;

	const secondaryNavLabels = vehicles.map((_vehicle) =>
		getModelTitle(largeVehicleModelNavigationLabel, _vehicle)
	);

	return (
		<div>
			<Slider
				secondaryNavLabels={secondaryNavLabels}
				sliderContentClassnames="mx-10 py-5"
			>
				{React.Children.toArray(
					vehicles.map((_vehicle) => (
						<LargeVehicleCard
							modelObject={_vehicle}
							modelLink={getModelLink(
								`${sitemap.get(modelLinkAlias)}`,
								modelLinkParameters,
								_vehicle
							)}
						/>
					))
				)}
			</Slider>
		</div>
	);
};

export default LargeVehicleContainer;

LargeVehicleContainer.propTypes = {
	vehicles: PropTypes.arrayOf(
		PropTypes.shape({
			year: PropTypes.number,
			make: PropTypes.string,
			model: PropTypes.string,
			gvBodyStyle: PropTypes.string,
			image: PropTypes.string,
			inventoryCount: PropTypes.number
		})
	).isRequired
};
