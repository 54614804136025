import { SET_LOADING } from '../actions/types';

const REDUCER_INITIAL_STATE = {
	error: null,
	status: true
};
export default function loading(
	state = REDUCER_INITIAL_STATE,
	{ type, payload }
) {
	switch (type) {
		case SET_LOADING:
			return { ...state, ...payload };

		default:
			return state;
	}
}
