/**
 * @param {URLSearchParams} searchParams - URL object to append queries to
 * @param {QueryObject} queryObj - Object of queries
 * @returns {string} - URL object with queries appended
 * @example
 * const url = new URL('http://localhost/vehicles');
 * addUrlSearchParams(url.searchParams, {makes:["ford", "chrysler", "volvo", "audi", "honda"]});
 * url.toString(); // http://localhost/vehicles?makes[]=ford&makes[]=chrysler&makes[]=volvo&makes[]=audi&makes[]=honda
 * @example
 * const url = new URL('http://localhost/vehicles');
 * addUrlSearchParams(url.searchParams, {makes:["ford", "chrysler", "volvo", "audi", "honda"]}, true);
 * url.toString(); // http://localhost/vehicles?makes=ford,chrysler,volvo,audi,honda
 */
export const addUrlSearchParams = (searchParams, queryObj, useCSV = false) => {
	Object.entries(queryObj).forEach(([key, value]) => {
		if (!value) {
			return;
		}
		if (Array.isArray(value) && !useCSV) {
			value.forEach((currValue) => {
				searchParams.append(`${key}[]`, currValue);
			});
		} else if (Array.isArray(value) && useCSV) {
			searchParams.append(key, value.join(','));
		} else {
			searchParams.append(key, value);
		}
	});
};
