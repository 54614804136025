import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useRequestData } from 'wsm-common-data';

const Tab = ({ children, parentId, eventKey, tabClassNames = '' }) => {
	const tabId = `${parentId}-pane-${eventKey}`;
	const { deviceType } = useRequestData();
	const isDesktop = deviceType === 'DESKTOP';

	const tabClasses = setClassNames([
		'fade',
		'tab-pane',
		'active in',
		tabClassNames,
		isDesktop ? 'carousel' : null
	]);

	return (
		<div id={tabId} role="tabpanel" className={tabClasses}>
			{children}
		</div>
	);
};

Tab.propTypes = {
	eventKey: PropTypes.string,
	parentId: PropTypes.string,
	children: PropTypes.element,
	tabClassNames: PropTypes.string
};

export default Tab;
