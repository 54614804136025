import React from 'react';
import { useLabels } from 'wsm-common-data';
import logger from 'ws-scripts/modules/logger';
import { ErrorBoundary } from 'wsm-error-boundary';

import { Error } from '../components/Error';
import { DataFetcher } from '../components/DataFetcher';

import '../global-sass/main.scss';

const WidgetContainer = () => {
	const labels = useLabels();

	return (
		<ErrorBoundary
			errorMarkup={
				<Error
					message={labels.get('TECHNICAL_DIFFICULTIES_VERBIAGE')}
				/>
			}
			errorHandler={(error, errorInfo) => {
				const newError = new Error(
					`Encountered an error in ws-model-selector.\n${error}`
				);
				newError.originalError = error;
				newError.originalStackTrace = errorInfo.componentStack;
				logger.error(`${newError}\n${newError.originalStackTrace}`);
			}}
		>
			<DataFetcher />
		</ErrorBoundary>
	);
};

export default WidgetContainer;
