export const getModelLink = (modelLinkPath, modelLinkParameters, vehicle) => {
	const validParams = ['year', 'make', 'model', 'gvBodyStyle'];
	const modelLinkParameterArray = modelLinkParameters
		.split(',')
		.reduce((paramsArray, currentParam) => {
			if (validParams.includes(currentParam)) {
				paramsArray.push(
					`${currentParam}=${vehicle[currentParam]
						?.toString()
						?.replace(/ /g, '+')}`
				);
			}
			return paramsArray;
		}, []);
	return `${modelLinkPath}?${modelLinkParameterArray.join('&')}`;
};
