import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

export const Error = ({ message }) => {
	return (
		<Alert bsStyle="danger">
			<i className="ddc-icon ddc-icon-alert" aria-hidden="true" />
			<div className="alert-content">{message}</div>
		</Alert>
	);
};

Error.propTypes = {
	message: PropTypes.string.isRequired
};
