import loading from './loading';
import vehicles from './vehicles';
import widgetData from './widgetData';

const root = {
	loading,
	vehicles,
	widgetData
};

export default root;
